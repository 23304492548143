export const orderStatuses = {
  10: {
    name: 'order_status_10_name',
    description: 'order_status_10_description',
    color: 'primary'
  },
  20: {
    name: 'order_status_20_name',
    description: 'order_status_20_description',
    color: 'secondary'
  },
  30: {
    name: 'order_status_30_name',
    description: 'order_status_30_description',
    color: 'secondary'
  },
  40: {
    name: 'order_status_40_name',
    description: 'order_status_40_description',
    color: 'info'
  },
  50: {
    name: 'order_status_50_name',
    description: 'order_status_50_description',
    color: 'info'
  },
  60: {
    name: 'order_status_60_name',
    description: 'order_status_60_description',
    color: 'success'
  },
  70: {
    name: 'order_status_70_name',
    description: 'order_status_70_description',
    color: 'success'
  },
  75: {
    name: 'order_status_75_name',
    description: 'order_status_75_description',
    color: 'success'
  },
  100: {
    name: 'order_status_100_name',
    description: 'order_status_100_description',
    color: 'warning'
  },
  110: {
    name: 'order_status_110_name',
    description: 'order_status_110_description',
    color: 'warning'
  },
  120: {
    name: 'order_status_120_name',
    description: 'order_status_120_description',
    color: 'warning'
  },
  130: {
    name: 'order_status_130_name',
    description: 'order_status_130_description',
    color: 'warning'
  }
};

export const userStatuses = {
  0: {
    name: 'user_type_0_name',
    description: 'user_type_100_description'
  },
  100: {
    name: 'user_type_100_name',
    description: 'user_type_100_description'
  },
  110: {
    name: 'user_type_110_name',
    description: 'user_type_110_description'
  },
  150: {
    name: 'user_type_150_name',
    description: 'user_type_150_description'
  },
  160: {
    name: 'user_type_160_name',
    description: 'user_type_160_description'
  },
  200: {
    name: 'user_type_200_name',
    description: 'user_type_200_description'
  },
  250: {
    name: 'user_type_250_name',
    description: 'user_type_250_description'
  },
  300: {
    name: 'user_type_300_name',
    description: 'user_type_300_description'
  }
};

//optimizer_status
export const locationStatuses = {
  1: {
    name: 'location_status_adding_in_progress',
    actualValue: 'Adding in progress',
    color: 'secondary'
  },
  2: {
    name: 'location_status_geocoding_fail',
    actualValue: 'Geocoding Fail',
    color: 'danger'
  },
  3: {
    name: 'location_status_distances_in_progress',
    actualValue: 'Distances in progress',
    color: 'primary'
  },
  4: {
    name: 'location_status_finished',
    actualValue: 'Finished',
    color: 'success'
  },
  5: {
    name: 'location_status_connection_failure',
    actualValue: 'Connection Failure',
    color: 'danger'
  }
};

export const weekDays = [
  { value: 1, label: 'weekday_monday' },
  { value: 2, label: 'weekday_tuesday' },
  { value: 3, label: 'weekday_wednesday' },
  { value: 4, label: 'weekday_thursday' },
  { value: 5, label: 'weekday_friday' },
  { value: 6, label: 'weekday_saturday' },
  { value: 7, label: 'weekday_sunday' }
];
